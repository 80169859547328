import * as React from "react";
import { graphql, Link } from "gatsby";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import Header from "../common/header/Header";
import Footer from "../common/footer/Footer";
import VideoTwo from "../components/video/VideoTwo";
import Separator from "../components/Separator";
import SplitContentImage from "../components/SplitContentImage";
import TurnOnAttentionVideo from "../content/TurnOnAttentionVideo";
import { GatsbyImage } from "gatsby-plugin-image";

const PopupData = [
  {
    id: "01",
    image: "https://img.youtube.com/vi/T9sHJL8AP44/maxresdefault.jpg",
    popupLink: ["https://www.youtube.com/watch?v=T9sHJL8AP44"],
  },
];

const PartnersPage = ({ data }) => {
  return (
    <>
      <SEO title="Partners" />

      <main className="page-wrapper">
        {/*<HeaderTopNews />*/}
        <Header />
        {/* Start Slider Area  */}
        <div className="slider-area slider-style-6 shape-left inheader-not-transparent height-750">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-start">
                  <h1 className="title color-white">Become a partner</h1>
                  <p className="description color-white">
                    Light up your clients with HueBox by becoming an official
                    partner today!
                  </p>
                  <div className="button-group mt--30 mt_sm--20">
                    <Link
                      className="btn-default btn-medium btn-icon btn-border"
                      to="/contact"
                    >
                      Lets talk{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                {PopupData.map((item) => (
                  <div className="video-btn" key={item.id}>
                    <VideoTwo imagename={item.image} galleryItem={item} />
                  </div>
                ))}
              </div>
              {/*<div className="shape-image">*/}
              {/*  <img*/}
              {/*    src="./images/banner/white-shape.svg"*/}
              {/*    alt="Banner Images"*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        <Separator />
        {/* Start Elements Area  */}
        <div className="rwt-split-area rn-section-gap">
          <div className="wrapper">
            <div className="container">
              <SplitContentImage
                // contentSize="large"
                // reverse
                inContainer
                title="Become a partner"
                image={
                  <GatsbyImage
                    image={data.mainImage.childImageSharp.gatsbyImageData}
                  />
                }
              >
                <p>
                  Are you a value added re-seller or distributor of lighting
                  systems looking for new opportunities to grow?
                </p>
                <p>
                  Are you already involved in design and supply of innovative
                  LED lighting systems and are looking for creative ways to help
                  your clients?
                </p>
                <p>
                  Are you in marketing or advertising and working with clients
                  where visual branding and messaging is important?
                </p>
                <p>
                  Do you want to work at the forefront of LED Lightbox
                  technology?
                </p>
                <p>
                  HueBox provide the worlds most dynamic LED Lightbox systems
                  including the programming needed to use dynamic LED Lightboxes
                  with RGB at their best .
                </p>
                <p>
                  We work closely with our partners and help them with our
                  experienced design, operational and support teams.
                </p>
                <p>
                  Why not get in touch and explore how HueBox could be a
                  valuable addition to your sales and marketing
                </p>
              </SplitContentImage>
            </div>
          </div>
        </div>
        {/*<Separator />*/}
        {/*<div className="rn-section-gap">*/}
        {/*  <div className="button-group mt--30 mt_sm--20 text-center">*/}
        {/*    <Link*/}
        {/*      className="btn-default btn-medium btn-icon btn-border"*/}
        {/*      to="/contact"*/}
        {/*    >*/}
        {/*      Lets talk{" "}*/}
        {/*      <i className="icon">*/}
        {/*        <FiArrowRight />*/}
        {/*      </i>*/}
        {/*    </Link>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <Separator />
        <div className="rn-section-gap">
          <TurnOnAttentionVideo />
        </div>
        <Footer />
      </main>
    </>
  );
};

export default PartnersPage;
export const query = graphql`
  {
    mainImage: file(relativePath: { eq: "Abstract_RGB_Board.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1280
          quality: 80
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
  }
`;
