import React from "react";
import { SplitContentVideo } from "../components/SplitContentVideo";
import { Link } from "gatsby";
import { FeatureList } from "../components/FeatureList";

const TurnOnAttentionVideo = () => {
  const video = {
    id: "01",
    image: "https://img.youtube.com/vi/T9sHJL8AP44/maxresdefault.jpg",
    popupLink: ["https://www.youtube.com/watch?v=T9sHJL8AP44"],
  };
  return (
    <SplitContentVideo video={video}>
      <h3
        style={{
          fontWeight: 400,
          marginBottom: 35,
        }}
      >
        Turn on their{" "}
        <strong
          style={{
            color: "var(--color-primary)",
          }}
        >
          Attention
        </strong>
      </h3>
      <FeatureList
        items={[
          {
            title: "Visual Impact",
            content: `HueBox is a leader in the development and use of dynamic LED
              lightboxes that deliver stunning visual impact across a wide range
              of promotional settings.`,
          },
          {
            title: "Attention grabbing",
            content: `Turn on the attention of your audience with a unique HueBox
              designed for your business.`,
          },
        ]}
      />
      <div className="about-btn mt--30">
        <Link className="btn-default" to="/about">
          About HueBox
        </Link>
      </div>
    </SplitContentVideo>
  );
};

export default TurnOnAttentionVideo;
